<template>
  <article class="tpl-collection mb-12 space-y-12" :data-uuid="uuid">
    <div>
      <PageHeader
        :title="title"
        :subtitle="subtitle"
        :background="image"
        bg-color="sky"
      >
        <template v-if="subtitle" #subtitle>
          <div class="subtitle">
            {{ subtitle }}
          </div>
        </template>
      </PageHeader>
      <PagePrevNextNav :prev="links?.prev" :next="links?.next" />
    </div>

    <Wysiwyg :="body">
      <template
        v-if="promotions?.length"
        v-for="card in promotions"
        :key="card.uuid"
      >
        <Card :="card" />
      </template>
    </Wysiwyg>

    <section class="space-y-8">
      <LytGridFourUp :length="articles.length">
        <template #item="{ idx }">
          <template
            v-for="card in [getCardAt(articles, idx)]"
            :key="card?.uuid"
          >
            <CardBasic
              v-if="card"
              :uuid="card.uuid"
              :badge="card.badge"
              :label="card.title"
              :body="card.body"
              :image="card.image"
              :path="withoutHost(card.url)"
            />
          </template>
        </template>
      </LytGridFourUp>
    </section>

    <SectionMoreLikeThis class="space-y-8" :uuid="uuid" :lang="locale" />
  </article>
</template>

<script lang="ts" setup>
import { mapValues, shake } from 'radash'
import { getCardAt } from '~/utils/get-card'
import { withoutHost } from '~/utils/without-host'
import * as logger from '~/utils/logging'
import type { ContentBody, ContentImage } from '~/types'

const props = defineProps<{
  uuid: string
  locale: string
  title: string
  subtitle?: string
  body: ContentBody
  image?: ContentImage

  collectionContent?: {
    uuid: string
    badge?: string
    title: string
    body?: ContentBody
    image?: ContentImage
    url: string
  }[]
}>()

const { locale } = useI18n()

if (!props.uuid) {
  logger.error('[Collection] Missing uuid', { props })
}

const articles = computed(() => props?.collectionContent || [])

const { data: promotions } = useLazyFetch(
  `/api/data/content/${props.uuid}/promotions`,
  {
    params: { lang: props.locale, limit: 3 },
  },
)

const { data: _links } = useLazyFetch(`/api/data/content/${props.uuid}/links`, {
  params: { lang: locale },
  pick: ['prev', 'next'],
})

const links = computed(() => {
  const vals = unref(_links)
  if (!vals) return

  return mapValues(shake(vals), ({ label, path }) => ({
    label,
    path: withoutHost(path),
  })) as Record<string, { label: string; path: string }>
})
</script>
